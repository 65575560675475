import { defineStore } from "pinia";

const STORE_NAME = "store";

/**
 * @typedef {object} Store
 * @property {string} userFirstName - User's first name
 * @property {string} userLastName - User's last name
 * @property {string} userEmail - User's email
 * @property {string} userMarketingOptIn - User's marketing opt-in
 * @property {string} activation_key - Activation key
 * @property {string} survey_key - Survey key
 * @property {import("~/utils/events").Event[]} events - Events
 * @property {object} selectedEvent - Selected event
 * @property {boolean} adminLoggedIn - Admin logged in
 * @property {object} ready - Ready questions
 * @property {string} ready.currentQuestion - Current question
 * @property {string} ready.Question_1 - Question 1
 * @property {string} ready.Question_2 - Question 2
 * @property {object} car - Car questions
 * @property {string} car.currentQuestion - Current question
 * @property {string} car.Question_3 - Question 3
 * @property {string} car.Question_4a - Question 4a
 * @property {string} car.Question_4b - Question 4b
 * @property {string} car.Question_5a - Question 5a
 * @property {string} car.Question_5b - Question 5b
 * @property {string} car.Question_6a - Question 6a
 * @property {string} car.Question_6b - Question 6b
 * @property {object} roadtrip - Roadtrip questions
 * @property {string} roadtrip.currentQuestion - Current question
 * @property {string} roadtrip.Question_7 - Question 7
 * @property {string} roadtrip.Question_8 - Question 8
 * @property {string} roadtrip.Question_9 - Question 9
 * @property {string} roadtrip.Question_10 - Question 10
 */

/**
 * Returns the store
 * @returns {Store} Returns the default store
 */
const getDefaultStore = () => ({
  userFirstName: "",
  userLastName: "",
  userEmail: "",
  userMarketingOptIn: "",
  activation_key: "",
  survey_key: "",
  events: [],
  selectedEvent: null,
  adminLoggedIn: false,
  ready: {
    currentQuestion: "Question_1",
    Question_1: "",
    Question_2: "",
  },
  car: {
    currentQuestion: "Question_3",
    Question_3: "",
    Question_4a: "",
    Question_4b: "",
    Question_5a: "",
    Question_5b: "",
    Question_6a: "",
    Question_6b: "",
  },
  roadtrip: {
    currentQuestion: "Question_7",
    Question_7: "",
    Question_8: "",
    Question_9: "",
    Question_10: "",
  },
});

/**
 * Returns the store
 * @returns {Store} Returns the store
 */
const getStore = () => {
  if (import.meta.server) {
    return getDefaultStore();
  } else {
    const store = localStorage.getItem(STORE_NAME);

    return store ? JSON.parse(store) : getDefaultStore();
  }
};

/**
 * Store
 * @returns {import("pinia").Store<Store>} Returns the store
 */
const useStore = defineStore(STORE_NAME, {
  actions: {
    resetAnswers() {
      this.ready = {
        currentQuestion: "Question_1",
        Question_1: "",
        Question_2: "",
      };
      this.car = {
        currentQuestion: "Question_3",
        Question_3: "",
        Question_4a: "",
        Question_4b: "",
        Question_5a: "",
        Question_5b: "",
        Question_6a: "",
        Question_6b: "",
      };
      this.roadtrip = {
        currentQuestion: "Question_7",
        Question_7: "",
        Question_8: "",
        Question_9: "",
        Question_10: "",
      };
    },
  },
  getters: {
    isGettingReadyComplete: (state) => {
      return () => (state.ready.Question_1.length && state.ready.Question_2.length ? true : false);
    },
    isCarComplete: (state) => {
      return () =>
        state.car.Question_3.length &&
        (state.car.Question_4a.length || state.car.Question_4b.length) &&
        (state.car.Question_5a.length || state.car.Question_5b.length) &&
        (state.car.Question_6a.length || state.car.Question_6b.length) ?
          true :
          false;
    },
    isRoadTripComplete: (state) => {
      return () =>
        state.roadtrip.Question_7.length &&
        state.roadtrip.Question_8.length &&
        state.roadtrip.Question_9.length &&
        state.roadtrip.Question_10.length ?
          true :
          false;
    },
    isQuizComplete: (state) => {
      return () => {
        if (
          state.ready.Question_1.length &&
          state.ready.Question_2.length &&
          state.car.Question_3.length &&
          (state.car.Question_4a.length || state.car.Question_4b.length) &&
          (state.car.Question_5a.length || state.car.Question_5b.length) &&
          (state.car.Question_6a.length || state.car.Question_6b.length) &&
          state.roadtrip.Question_7.length &&
          state.roadtrip.Question_8.length &&
          state.roadtrip.Question_9.length &&
          state.roadtrip.Question_10.length
        ) {
          return true;
        }
        return false;
      };
    },
  },
  persist: true,
  state: () => getStore(),
});

export { useStore };
